<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.go(-1)"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">View Appointment</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-4 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div
                    class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
                    <h4 class="mb-0 lg-bld mt-3">First Attempt</h4>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-12 col-sm-6">
                        <button
                          id="save-button"
                          class="fill-btn px-4 ml-1"
                          v-on:click="showPopup('FIRST')">
                          Invite
                        </button>
                        <button
                          v-if="detail.ExameDate1"
                          id="save-button"
                          class="fill-btn px-4 ml-1"
                          v-on:click="showResultPopup('FIRST')">
                          Result
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div
                    class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
                    <h4 class="mb-0 lg-bld mt-3">Second Attempt</h4>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-12 col-sm-6">
                        <button
                          id="save-button"
                          class="fill-btn px-4 ml-1"
                          v-on:click="showPopup('SECOND')">
                          Invite
                        </button>
                        <button
                          v-if="detail.ExameDate2"
                          id="save-button"
                          class="fill-btn px-4 ml-1"
                          v-on:click="showResultPopup('SECOND')">
                          Result
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div
                    class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
                    <h4 class="mb-0 lg-bld mt-3">Third Attempt</h4>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-12 col-sm-6">
                        <button
                          id="save-button"
                          class="fill-btn px-4 ml-1"
                          v-on:click="showPopup('THIRD')">
                          Invite
                        </button>
                        <button
                          v-if="detail.ExameDate3"
                          id="save-button"
                          class="fill-btn px-4 ml-1"
                          v-on:click="showResultPopup('THIRD')">
                          Result
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-10 mt-2">
                <div class="card border mb-0 radius-15">
                  <!-- <div
                    class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
                    <h4 class="mb-0 lg-bld mt-3">Minor Case</h4>
                  </div> -->
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-12 col-sm-6">
                        <button
                          id="save-button"
                          class="fill-btn px-4 ml-1"
                          v-on:click="showMinorPopup()">
                          Minor Case
                        </button>
                        <button
                          id="save-button"
                          class="fill-btn px-4 ml-1"
                          v-on:click="showStudyPermissionPopup()">
                          Study Permission
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TextModal ref="textModal">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $helperService.getTitleCase(this.type) }} Attempt
          </h5>
          <button type="button" class="close" v-on:click="Closepopup()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Form ref="AttemptDateForm" @submit="updatetemptdate">
          <div class="col-lg-12 col-sm-12">
            <div class="col-12 form-group">
              <label>Attempt Date</label>
              <span class="text-danger">*</span>
              <Field
                autoApply
                type="text"
                name="attempt_date"
                class="form-control"
                placeholder="Select Date"
                v-model="attempt_date"
                v-slot="{ field }"
                rules="required:attempt date"
                :validateOnInput="true">
                <datepicker
                  v-bind="field"
                  v-model="attempt_date"
                  class="form-control"
                  format="dd-mm-yyyy"
                  placeholder="Attempt Date"
                  :clearable="false"
                  style="background-color: white !important" />
              </Field>
              <ErrorMessage name="attempt_date" class="text-danger" />
            </div>
          </div>
          <div class="modal-footer pb-0">
            <button
              class="fill-btn"
              id="attempt-date-button"
              style="width: 20%; border-radius: 5px">
              Save
            </button>
            <button
              type="button"
              class="fil-btn"
              id="cancel-button"
              v-on:click="Closepopup()"
              style="width: 20%; border-radius: 5px">
              Cancel
            </button>
          </div>
        </Form>
      </TextModal>
      <TextModal ref="ResultTextModal">
        <div class="modal-header">
          <h5 class="modal-title">Result</h5>
          <button type="button" class="close" v-on:click="CloseResultpopup()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Form ref="ResultForm" @submit="UpdateResult()">
          <div class="col-lg-12 col-sm-12">
            <div class="col-lg-12 col-sm-12 d-flex">
              <div class="form-group row ml-1">
                <label class="mr-3">Result</label>
                <div class="mr-4">
                  <Field
                    type="radio"
                    id="all"
                    name="all"
                    value="PASS"
                    v-model="resultDetail.ispass" />
                </div>
                <label for="all" class="mr-3">PASS</label>
                <div>
                  <Field
                    type="radio"
                    id="specificUser"
                    name="all"
                    value="FAIL"
                    v-model="resultDetail.ispass" />
                </div>
                <label for="specificUser" class="ml-2">FAIL</label>
              </div>
            </div>
            <div class="col-12 row">
              <div class="col-lg-6 col-sm-6">
                <div class="form-group">
                  <label>Score</label>
                  <!-- <span class="text-danger">*</span> -->
                  <Field
                    type="text"
                    name="Score"
                    class="form-control"
                    placeholder="Enter Score"
                    v-model="resultDetail.score"
                    @keypress="$helperService.allowOnlyNumericValue($event)"
                    :validateOnInput="true" />
                  <!-- rules="required:Score" -->
                  <!-- <ErrorMessage name="Score" class="text-danger" /> -->
                </div>
              </div>
              <div class="col-lg-6 col-sm-6">
                <div class="form-group">
                  <label>Out Off</label>
                  <!-- <span class="text-danger">*</span> -->
                  <Field
                    type="text"
                    name="outoff"
                    class="form-control"
                    placeholder="Enter Out Off"
                    v-model="resultDetail.outoff"
                    @keypress="$helperService.allowOnlyNumericValue($event)"
                    :validateOnInput="true" />

                  <!-- rules="required:Name"
                  <ErrorMessage name="outoff" class="text-danger" /> -->
                </div>
              </div>
              <div class="col-lg-6 col-sm-6">
                <div class="form-group">
                  <label>Grade</label>
                  <!-- <span class="text-danger">*</span> -->
                  <Field
                    type="text"
                    name="Grade"
                    class="form-control"
                    placeholder="Enter Grade"
                    v-model="resultDetail.grade"
                    :validateOnInput="true" />
                  <!-- rules="required:Grade"
                  <ErrorMessage name="Grade" class="text-danger" /> -->
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer pb-0">
            <button
              class="fill-btn"
              id="change-password-button"
              style="width: 20%; border-radius: 5px">
              Save
            </button>
            <button
              type="button"
              class="fil-btn"
              id="cancel-button"
              v-on:click="CloseResultpopup()"
              style="width: 20%; border-radius: 5px">
              Cancel
            </button>
          </div>
        </Form>
      </TextModal>
      <TextModal ref="MinorPopup">
        <div class="modal-header">
          <h5 class="modal-title">Minor Case</h5>
          <button type="button" class="close" v-on:click="CloseMinorPopup()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Form ref="MinorPopupForm" @submit="UpdateMinorCase()">
          <div class="col-lg-12 col-sm-12">
            <div class="col-12 row">
              <div class="col-lg-12 col-sm-12">
                <div class="form-group">
                  <label>Name </label>
                  <!-- <span class="text-danger">*</span> -->
                  <Field
                    type="text"
                    name="minor_name"
                    class="form-control"
                    placeholder="Enter Name"
                    v-model="minorCaseDetail.minor_name"
                    :validateOnInput="true" />
                  <!-- rules="required:Score" -->
                  <!-- <ErrorMessage name="Name" class="text-danger" /> -->
                </div>
              </div>
              <div class="col-lg-12 col-sm-6">
                <div class="form-group">
                  <label>Date Of Birth (Ex: YYYY-MM-DD)</label>
                  <!-- <span class="text-danger">*</span> -->
                  <Field
                    type="text"
                    name="minor_dob"
                    class="form-control"
                    placeholder="Enter Date Of Birth"
                    v-model="minorCaseDetail.minor_dob"
                    rules="required:date of birth"
                    :validateOnInput="true" />
                  <ErrorMessage name="date_of_birth" class="text-danger" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer pb-0">
            <button
              class="fill-btn"
              id="change-password-button"
              style="width: 20%; border-radius: 5px">
              Save
            </button>
            <button
              type="button"
              class="fil-btn"
              id="cancel-button"
              v-on:click="CloseMinorPopup()"
              style="width: 20%; border-radius: 5px">
              Cancel
            </button>
          </div>
        </Form>
      </TextModal>
      <TextModal ref="StudyPermissionPopup">
        <div class="modal-header">
          <h5 class="modal-title">Study Permission</h5>
          <button
            type="button"
            class="close"
            v-on:click="CloseStudyPermissionPopup()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Form ref="StudyPermissionPopupForm" @submit="UpdateStudyPermission()">
          <div class="col-lg-12 col-sm-12">
            <div class="col-12 row">
              <div class="col-lg-12 col-sm-12">
                <div class="form-group">
                  <label>Name</label>
                  <!-- <span class="text-danger">*</span> -->
                  <Field
                    type="text"
                    name="study_name"
                    class="form-control"
                    placeholder="Enter Name"
                    v-model="studyCaseDetail.study_name"
                    :validateOnInput="true" />
                  <!-- rules="required:Score" -->
                  <!-- <ErrorMessage name="Name" class="text-danger" /> -->
                </div>
              </div>
              <div class="col-lg-12 col-sm-12">
                <div class="form-group">
                  <label>University Name</label>
                  <!-- <span class="text-danger">*</span> -->
                  <Field
                    type="text"
                    name="study_university_name"
                    class="form-control"
                    placeholder="Enter University Name"
                    v-model="studyCaseDetail.study_university_name"
                    :validateOnInput="true" />

                  <!-- rules="required:Name"
                  <ErrorMessage name="outoff" class="text-danger" /> -->
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer pb-0">
            <button
              class="fill-btn"
              id="change-password-button"
              style="width: 20%; border-radius: 5px">
              Save
            </button>
            <button
              type="button"
              class="fil-btn"
              id="cancel-button"
              v-on:click="CloseStudyPermissionPopup()"
              style="width: 20%; border-radius: 5px">
              Cancel
            </button>
          </div>
        </Form>
      </TextModal>
    </div>
  </div>
  <ErrorComponent @retry="getDetail()" ref="errorComponent" />
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import TextModal from "@/components/TextModal";
import datepicker from "vue3-datepicker";
import moment from "moment";
export default {
  name: "AddAdmin",
  components: {
    TextModal,
    datepicker,
    Form,
    Field,
    ErrorMessage,
    ErrorComponent,
  },
  data() {
    return {
      id: this.$route.params.id,
      roleList: [],
      detail: {
        password: "",
      },
      resultDetail: {
        ispass: "",
        score: "",
        outoff: "",
        grade: "",
      },
      passwordFieldType: "password",
      passwordFieldType2: "password",
      image: "/images/show.png",
      images: "/images/show.png",
      user_type: "",
      type: "",
      attempt_date: "",
      minorCaseDetail: {
        minor_name: "",
        minor_dob: "",
      },
      studyCaseDetail: {
        study_name: "",
        study_university_name: "",
      },
    };
  },
  mounted() {
    this.$helperService.showMenu("true");
    if (this.id) {
      this.getDetail();
    }
  },

  methods: {
    showMinorPopup() {
      this.$refs.MinorPopup.showModal();
      if (
        !this.minorCaseDetail &&
        (!this.minorCaseDetail.minor_dob || !this.minorCaseDetail.minor_name)
      ) {
        this.$refs.MinorPopupForm.resetForm();
      }
    },
    showStudyPermissionPopup() {
      this.$refs.StudyPermissionPopup.showModal();
      if (
        !this.studyCaseDetail &&
        (!this.studyCaseDetail.study_name ||
          !this.studyCaseDetail.study_university_name)
      ) {
        this.$refs.StudyPermissionPopupForm.resetForm();
      }
    },
    CloseMinorPopup() {
      this.$refs.MinorPopup.closeModal();
      this.$refs.MinorPopupForm.resetForm();
    },
    CloseStudyPermissionPopup() {
      this.$refs.StudyPermissionPopup.closeModal();
      this.$refs.StudyPermissionPopupForm.resetForm();
    },
    showPopup(type) {
      this.type = type;
      if (type == "FIRST") {
        this.attempt_date = this.detail.ExameDate1;
      } else if (type == "SECOND") {
        this.attempt_date = this.detail.ExameDate2;
      } else if (type == "THIRD") {
        this.attempt_date = this.detail.ExameDate3;
      }
      this.$refs.textModal.showModal();
      this.$refs.AttemptDateForm.resetForm();
    },
    Closepopup() {
      this.type = "";
      this.$refs.textModal.closeModal();
      this.$refs.AttemptDateForm.resetForm();
    },
    showResultPopup(type) {
      this.type = type;
      if (this.detail) {
        if (type == "FIRST") {
          this.resultDetail.ispass = this.detail.ispass1 ? "PASS" : "FAIL";
          this.resultDetail.score = this.detail.score1;
          this.resultDetail.outoff = this.detail.outoff1;
          this.resultDetail.grade = this.detail.grade1;
          this.resultDetail.outoff = this.detail.outof1;
        } else if (type == "SECOND") {
          this.resultDetail.ispass = this.detail.ispass2 ? "PASS" : "FAIL";
          this.resultDetail.score = this.detail.score2;
          this.resultDetail.outoff = this.detail.outoff2;
          this.resultDetail.grade = this.detail.grade2;
          this.resultDetail.outoff = this.detail.outof2;
        } else if (type == "THIRD") {
          this.resultDetail.ispass = this.detail.ispass3 ? "PASS" : "FAIL";
          this.resultDetail.score = this.detail.score3;
          this.resultDetail.outoff = this.detail.outoff3;
          this.resultDetail.grade = this.detail.grade3;
          this.resultDetail.outoff = this.detail.outof3;
        }
      }
      this.$refs.ResultTextModal.showModal();
      this.$refs.resultForm.resetForm();
    },
    CloseResultpopup() {
      this.type = "";
      this.resultDetail = {
        ispass: "",
        score: "",
        outoff: "",
        grade: "",
        gid: "",
        type: "",
      };
      this.$refs.ResultTextModal.closeModal();
      this.$refs.resultForm.resetForm();
    },
    getDetail() {
      // this.$refs.errorComponent.updateFormLoader(true);
      this.$api
        .getAPI({
          _action: "/grievance-appointment/" + this.id,
        })
        .then((res) => {
          if (res && res.result) {
            this.detail = res.result;
          }
          if (res && res.grievance) {
            this.minorCaseDetail = res.grievance;
          }
          if (res && res.grievance) {
            this.studyCaseDetail = res.grievance;
          }
          // this.$refs.errorComponent.updateFormLoader(false);
        })
        .catch(() => {
          this.$refs.errorComponent.updateFormLoader(false);
        });
    },
    updatetemptdate() {
      if (this.id) {
        var obj = {};
        obj.gid = this.id;
        obj.type = this.type;
        obj.attempt_date = this.attempt_date
          ? moment(this.attempt_date).format("YYYY-MM-DD")
          : null;
        this.$api
          .putAPI({
            _action: "/update-griveance-attempt-date",
            _body: obj,
            _buttonId: "attempt-date-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.Closepopup();
            if (this.id) {
              this.getDetail();
            }
          })
          .catch(() => {});
      }
    },
    UpdateResult() {
      if (this.id) {
        var obj = this.resultDetail;
        obj.gid = this.id;
        obj.type = this.type;
        this.$api
          .putAPI({
            _action: "/update-griveance-result-details",
            _body: obj,
            _buttonId: "attempt-date-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            if (this.id) {
              this.getDetail();
            }
            this.CloseResultpopup();
          })
          .catch(() => {});
      }
    },
    UpdateMinorCase() {
      if (this.id) {
        var obj = this.minorCaseDetail;
        obj.id = this.id;
        this.$api
          .putAPI({
            _action: "/update-minor-case",
            _body: obj,
            _buttonId: "change-password-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            if (this.id) {
              this.getDetail();
            }
            this.CloseMinorPopup();
          })
          .catch(() => {});
      }
    },
    UpdateStudyPermission() {
      if (this.id) {
        var obj = this.studyCaseDetail;
        obj.id = this.id;
        this.$api
          .putAPI({
            _action: "/update-study-permission",
            _body: obj,
            _buttonId: "change-password-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            if (this.id) {
              this.getDetail();
            }
            this.CloseStudyPermissionPopup();
          })
          .catch(() => {});
      }
    },
  },
};
</script>
