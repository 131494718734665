<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm col-12 row">
          <div class="col-6">
            <h4 class="mb-0 lg-bld">DASHBOARD</h4>
          </div>
          <div class="col-6 col-lg-6 row" style="justify-content: end">
            <div class="col-3">
              <datepicker
                v-model="month"
                format="MMMM"
                placeholder="Month"
                month-picker
                autoApply
                @update:model-value="searchList()"
                style="background-color: white !important" />
            </div>
            <div class="col-3">
              <datepicker
                v-model="year"
                format="yyyy"
                placeholder="Year"
                year-picker
                autoApply
                @update:model-value="searchList()"
                style="background-color: white !important" />
            </div>
            <a
              class="fill-btn btn-style cursor-pointer"
              v-on:click="resetFilter()"
              >Reset</a
            >
          </div>
        </div>

        <div class="page-content">
          <div class="card-body py-0 px-2">
            <ErrorComponent
              @retry="getDashboardCount(0)"
              ref="errorComponent2" />
            <div class="row" v-if="!loader2" style="cursor: pointer">
              <div
                class="col-lg-3 col-md-6 col-sm-12 mb-4"
                v-on:click="showList('PENDING')">
                <div
                  class="card"
                  :style="type == 'PENDING' ? 'background-color: grey' : ''">
                  <div class="card-body">
                    <h8 class="card-mini-title-font-bold">Cases</h8>
                    <h5
                      class="card-title-font-bold"
                      style="text-decoration: underline">
                      Profile Submitted
                    </h5>
                    <p class="font-bold" style="color: red">
                      {{ dashboardcount?.profile_submitted }}
                    </p>
                    <p>
                      <i class="fa fa-refresh" aria-hidden="true"></i> Just
                      Updated
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-3 col-md-6 col-sm-12 mb-4"
                v-on:click="showList('DOC_SUBMITTED')">
                <div
                  class="card"
                  :style="
                    is_document_completed == 'DOC_SUBMITTED'
                      ? 'background-color: grey'
                      : ''
                  ">
                  <div class="card-body">
                    <h8 class="card-mini-title-font-bold">Cases</h8>
                    <h5
                      class="card-title-font-bold"
                      style="text-decoration: underline">
                      Doc Submitted
                    </h5>
                    <p class="font-bold" style="color: #ffc107">
                      {{ dashboardcount?.docment_submitted }}
                    </p>
                    <p>
                      <i class="fa fa-refresh" aria-hidden="true"></i> Just
                      Updated
                    </p>
                  </div>
                </div>
              </div>
              <!-- <div class="col-lg-2 col-md-6 col-sm-12 mb-4">
                <div class="card">
                  <div class="card-body">
                    <h8 class="card-mini-title-font-bold">Cases</h8>
                    <h5 class="card-title-font-bold">Verified</h5>
                    <p class="font-bold" style="color: orange">
                      {{ dashboardcount?.cases_verified }}
                    </p>
                    <p>
                      <i class="fa fa-refresh" aria-hidden="true"></i> Just
                      Updated
                    </p>
                  </div>
                </div>
              </div> -->
              <div
                class="col-lg-3 col-md-6 col-sm-12 mb-4"
                v-on:click="showList('APPROVED')">
                <div
                  class="card"
                  :style="
                    profile_status == 'APPROVED' ? 'background-color: grey' : ''
                  ">
                  <div class="card-body">
                    <h8 class="card-mini-title-font-bold">Cases</h8>
                    <h5
                      class="card-title-font-bold"
                      style="text-decoration: underline">
                      Approved
                    </h5>
                    <p class="font-bold" style="color: green">
                      {{ dashboardcount?.cases_approved }}
                    </p>
                    <p>
                      <i class="fa fa-refresh" aria-hidden="true"></i> Just
                      Updated
                    </p>
                  </div>
                </div>
              </div>
              <!-- <div class="col-lg-2 col-md-6 col-sm-12 mb-4">
                <div class="card">
                  <div class="card-body">
                    <h8 class="card-mini-title-font-bold">Cases</h8>
                    <h5 class="card-title-font-bold">Griviances</h5>
                    <p class="font-bold" style="color: blue">{{ dashboardcount?.cases_grevienced }}</p>
                    <p>
                      <i class="fa fa-refresh" aria-hidden="true"></i> Just
                      Updated
                    </p>
                  </div>
                </div>
              </div> -->
              <div
                class="col-lg-3 col-md-6 col-sm-12 mb-4"
                v-on:click="showList('ABOVE_18')">
                <div
                  class="card"
                  :style="
                    is_cases_above_18_count == 'ABOVE_18'
                      ? 'background-color: grey'
                      : ''
                  ">
                  <div class="card-body">
                    <h8 class="card-mini-title-font-bold">Cases</h8>
                    <h5
                      class="card-title-font-bold"
                      style="text-decoration: underline">
                      Month below 18 years
                    </h5>
                    <p class="font-bold" style="color: blue">
                      {{ dashboardcount?.cases_above_18 }}
                    </p>
                    <p>
                      <i class="fa fa-refresh" aria-hidden="true"></i> Just
                      Updated
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 row">
            <!-- <div class="col-lg-6 card-body py-0 px-2">
            <div class="card border mb-0 radius-15 mb-1">
                <div class="col-lg-12">
                  <div class="row col-lg-12 col-sm-6 my-auto" style="justify-content: center;">
                    <h5 class="card-mini-title-font-bold m-2">PROFILE SUBMITTED 2024</h5>
                  </div>
                  <canvas
                    id="goodCanvas1"
                    width="10"
                    height="10"
                    aria-label=""
                    role="img"></canvas>
                </div>
            </div>
          </div>
           <div class="col-lg-6 card-body py-0 px-2">
            <div class="card border mb-0 radius-15 mb-1">
                <div class="col-lg-12">
                  <div class="row col-lg-12 col-sm-6 my-auto" style="justify-content: center;">
                    <h5 class="card-mini-title-font-bold m-2">VERIFIED PROFILES 2024</h5>
                  </div>
                  <canvas
                    id="goodCanvas2"
                    width="50"
                    height="50"
                    aria-label=""
                    role="img"></canvas>
                </div>
            </div>
          </div> -->
          </div>
          <div class="card-body py-0 px-2" v-show="is_show">
            <div class="card border mb-0 radius-15 mt-3">
              <div class="card-body p-0 pb-4">
                <div class="shadow-btm">
                  <div class="row col-lg-6 col-sm-6 my-auto">
                    <h5 class="card-mini-title-font-bold">
                      All
                      {{
                        type == "PENDING"
                          ? "Profile Submitted"
                          : type == "APPROVED"
                          ? "Approved"
                          : type == "ABOVE_18"
                          ? "Cases above 18 years"
                          : "Document Submitted"
                      }}
                      List
                    </h5>
                  </div>
                  <div class="row col-lg-6 col-sm-6 my-auto">
                    <!-- <h3 class="card-title-font-bold">New Demise List</h3> -->
                  </div>
                </div>
                <div class="table-responsive">
                  <div class="tableFixHead">
                    <table
                      class="table table-striped table-bordered"
                      role="grid"
                      aria-describedby="example_info"
                      id="members-list">
                      <thead>
                        <tr role="row">
                          <th>Sr. No.</th>
                          <th>Employee Name</th>
                          
                          <th
                            id="id-mobile"
                            v-on:click="sorting('phone', 'id-mobile')">
                            Deaprtment/Designation
                          </th>
                          <th>Profile Status</th>
                          <th
                            id="id-mobile"
                            v-on:click="sorting('phone', 'id-mobile')">
                            Mobile
                          </th>
                          <th v-if="type == 'ABOVE_18'">Minor Name</th>
                          <th v-if="type == 'ABOVE_18'">Minor DOB</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td style="text-align: left">
                            {{
                              ($refs.listPagination.currentPage - 1) * 10 +
                              index +
                              1
                            }}
                          </td>
                          <td style="text-align: left">
                            {{ data.employee_name }}
                          </td>
                         
                          <td style="text-align: left">
                            {{ data.department }} / {{ data.designation }}
                          </td>
                          <td
                            style="text-align: left"
                            :style="
                              data.profile_status == 'APPROVED'
                                ? 'color: #4CAF50;font-weight: bold;'
                                : data.profile_status == 'REJECTED'
                                ? 'color: red;font-weight: bold;'
                                : 'color:#ff7007;font-weight: bold;'
                            ">
                            {{ data.profile_status }}
                          </td>
                          <td style="text-align: left">
                            {{ data.nominee_mobile }}
                          </td>
                           <td
                            style="text-align: left"
                            v-if="type == 'ABOVE_18'">
                            {{ data.minor_name }}
                          </td>
                          <td
                            style="text-align: left"
                            v-if="type == 'ABOVE_18'">
                            {{ data.minor_dob }}
                          </td>
                          <td>
                            <a
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="
                                $router.push('/view-grevience/' + data.id)
                              "
                              >View</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <ErrorComponent @retry="getList(0)" ref="errorComponent" />
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal @remove="deleteRecord($event)" ref="deleteModal" />
  </div>
</template>
<script>
// import { Chart, registerables } from "chart.js";
// Chart.register(...registerables);

// const data = {
//   labels: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
//   datasets: [
//     {
//       label: "My First Dataset",
//       data: [11, 16, 7, 3, 14, 9, 5, 2, 8, 10, 15, 20],
//       backgroundColor: [
//       "#1f77b4",
//       "#ff7f0e",
//       "#2ca02c",
//       "#d62728",
//       "#9467bd",
//       "#8c564b",
//       "#e377c2",
//       "#7f7f7f",
//       "#bcbd22",
//       "#17becf",
//       "#393b79",
//       "#637939"
//       ],
//     },
//   ],
// };
// const config = {
// type: 'bar',
//   data: data,
//   options: {
//     scales: {
//       y: {
//         beginAtZero: true
//       }
//     }
//   },
// };
// const config2 = {
//   type: "doughnut",
//   data: data,
//   options: {},
// };
import datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
export default {
  name: "Dashboard",
  components: {
    datepicker,
    ErrorComponent,
    Pagination,
  },
  data() {
    return {
      is_show: false,
      type: undefined,
      is_document_completed: undefined,
      profile_status: undefined,
      is_cases_above_18_count: undefined,
      loader: true,
      loader2: true,
      searchObj: {
        keyword: "",
      },
      offset: -1,
      list: [],
      user_type: "",
      dashboardcount: {},
      month: "",
      year: new Date().getFullYear(),
    };
  },

  watch: {
    "$route.fullPath"(newValue) {
      this.pageName = newValue.replace("/", "");
      this.$helperService.showMenu("true");
    },
  },
  mounted() {
    this.$helperService.showMenu("true");

    // const ctx = document.getElementById("goodCanvas1").getContext("2d");
    // new Chart(ctx, config);
    // const ctx2 = document.getElementById("goodCanvas2").getContext("2d");
    // new Chart(ctx2, config2);
    // this.getList(this.offset - 1);
    // this.getList(0);
    this.getDashboardCount();
  },
  methods: {
    showList(type) {
      this.is_show = true;
      this.type = type;
      this.profile_status = undefined;
      this.is_document_completed = undefined;
      this.is_cases_above_18_count = undefined;
      if (type == "PENDING") {
        this.profile_status = "";
      } else if (type == "DOC_SUBMITTED") {
        this.is_document_completed = "DOC_SUBMITTED";
      } else if (type == "APPROVED") {
        this.profile_status = "APPROVED";
      } else if (type == "ABOVE_18") {
        this.is_cases_above_18_count = "ABOVE_18";
      }
      this.getList(0);
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    searchList() {
      this.getList(0);
      this.getDashboardCount();
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.is_show = false;
      this.month = "";
      this.year = new Date().getFullYear();
      this.getList(0);
      this.getDashboardCount();
    },
    getDashboardCount() {
      this.$refs.errorComponent2.updateListLoader(true);
      this.loader2 = true;
      var obj = {};
      if (this.month) {
        obj.month = this.month.month + 1;
      }
      if (this.year) {
        obj.year = this.year;
      }
      this.$api
        .getAPI({
          _action: "/dashboard-count",
          _body: obj,
        })
        .then((res) => {
          this.loader = false;
          this.dashboardcount = res.result;
          this.$refs.errorComponent2.updateListLoader(false);
          this.loader2 = false;
        })
        .catch(() => {});
    },
    getList(page, orderBy, sortBy) {
      this.searchObj = {};
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      if (this.month) {
        this.searchObj.month = this.month.month + 1;
      } else {
        this.searchObj.month = "";
      }
      if (this.year) {
        this.searchObj.year = this.year;
      } else {
        this.searchObj.year = new Date().getFullYear();
      }
      this.searchObj.role = this.user_type;
      if (this.is_document_completed) {
        this.searchObj.is_document_completed = this.is_document_completed;
      }
      if (this.is_cases_above_18_count) {
        this.searchObj.is_cases_above_18_count = this.is_cases_above_18_count;
      }
      if (this.profile_status) {
        this.searchObj.profile_status = this.profile_status;
      }
      this.$api
        .getAPI({
          _action: "/grievance-pagination-list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No List Available");
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    showDeletePopup(data) {
      this.$refs.deleteModal.showModal(
        "Delete Admin",
        "Are you sure you want to delete " + data.name,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$api
        .deleteAPI({
          _action: "/user/" + detail.id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList();
            this.$notify({
              type: "success",
              text: "Admin deleted successfully",
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
  },
};
</script>
