<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">
              {{
                type == "GREVIENCE"
                  ? "Profile Submitted"
                  : "Manage " + $helperService.getTitleCase(type)
              }}
            </h4>
          </div>
        </div>
        <div
          class="page-content"
          v-show="accessObject?.is_view == 1 || !isShowAccessDeniedMessage">
          <div class="card-body py-0 px-2">
            <div class="row">
              <div class="col-lg-6 col-sm-6 form-group row">
                <div class="has-search ml-3">
                  <i
                    class="fa fa-search"
                    style="position: absolute; margin: 10px"
                    aria-hidden="true"></i>
                  <input
                    type="text"
                    class="form-control"
                    v-on:keyup.enter="getList(0)"
                    placeholder="Search"
                    v-model="searchObj.keyword" />
                </div>
                <a
                  class="fill-btn btn-style cursor-pointer ml-3"
                  v-on:click="getList(0)"
                  >Search</a
                >
                <a
                  class="fill-btn btn-style cursor-pointer"
                  v-on:click="resetFilter"
                  v-show="searchObj.keyword"
                  >Reset</a
                >
                <div class="col-lg-4">
                  <Field
                    as="select"
                    class="form-control"
                    id="inputRole"
                    name="profile_status"
                    v-on:change="getList(0)"
                    v-model="profile_status">
                    <option value="">Select Profile Status</option>
                    <option
                      v-for="(data, index) in profile_status_list"
                      :key="index"
                      :value="data.id">
                      {{ data.name }}
                    </option>
                  </Field>
                </div>
              </div>

              <div
                v-if="type == 'GREVIENCE'"
                style="cursor: pointer"
                class="col-lg-6 col-sm-6 txt-right form-group">
                <a
                  class="fill-btn cursor-pointer"
                  v-on:click="$router.push('add-grevience')"
                  >+ Add</a
                >
              </div>
            </div>
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="tableFixHead">
                    <table
                      class="table table-striped table-bordered"
                      role="grid"
                      aria-describedby="example_info"
                      id="members-list">
                      <thead>
                        <tr role="row">
                          <th>Sr. No.</th>
                          <th
                            class="sorting"
                            id="id-name"
                            v-on:click="sorting('employee_name', 'id-name')">
                            Employee Name
                          </th>
                          <th
                            class="sorting"
                            id="id-email"
                            v-on:click="sorting('email', 'id-email')">
                            Email
                          </th>
                          <th
                            class="sorting"
                            id="id-department"
                            v-on:click="sorting('department', 'id-department')">
                            Department
                          </th>
                          <th
                            class="sorting"
                            id="id-designation"
                            v-on:click="
                              sorting('designation', 'id-designation')
                            ">
                            Designation
                          </th>
                          <th>Profile Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td style="text-align: left">
                            {{
                              ($refs.listPagination.currentPage - 1) * 10 +
                              index +
                              1
                            }}
                          </td>
                          <td style="text-align: left">
                            {{ data.employee_name }}
                          </td>
                          <td style="text-align: left">
                            {{ data.email }}
                          </td>
                          <td style="text-align: left">
                            {{ data.department }}
                          </td>
                          <td style="text-align: left">
                            {{ data.designation }}
                          </td>
                          <td
                            style="text-align: left"
                            :style="
                              data.profile_status == 'APPROVED'
                                ? 'color: #4CAF50;font-weight: bold;'
                                : data.profile_status == 'REJECTED'
                                ? 'color: red;font-weight: bold;'
                                : 'color:#ff7007;font-weight: bold;'
                            ">
                            {{ data.profile_status }}
                          </td>
                          <td style="text-align: left">
                            <a
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="
                                $router.push('/view-time-log/' + data.id)
                              "
                              >View</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <ErrorComponent @retry="getList(0)" ref="errorComponent" />
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="text-center"
          style="margin-top: 20%"
          v-show="accessObject?.is_view == 0 || isShowAccessDeniedMessage">
          <h3>You do not have access to view admin</h3>
        </div>
      </div>
    </div>
    <DeleteModal @remove="deleteRecord($event)" ref="deleteModal" />
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination";
import { Field } from "vee-validate";
export default {
  name: "manage_profile_time_log",
  components: {
    ErrorComponent,
    DeleteModal,
    Pagination,
    Field,
  },
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("Manage staff"),
      loader: true,
      assignList: [],
      isShowAccessDeniedMessage: false,
      searchObj: {
        keyword: "",
      },
      offset: -1,
      list: [],
      type: "",
    };
  },
  watch: {
    "$route.fullPath"(newValue) {
      this.pageName = newValue.replace("/", "");
      // this.getUserType();
      this.$helperService.showMenu("true");
      this.getList(0);
    },
  },
  mounted() {
    this.$helperService.showMenu("true");
    this.getList(0);
  },
  methods: {
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.searchObj.profile_status = this.profile_status
        ? this.profile_status
        : "";
      this.$api
        .getAPI({
          _action: "/grievance-pagination-list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          this.accessObject =
            this.$helperService.getAccessLevel("Manage staff");
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Admin List Available");
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(0);
    },
  },
};
</script>
