<template>
  <div>
    <div class="sidebar-wrapper" style="overflow-y: scroll" id="sidebar-div">
      <!-- <div class="sidebar-header">
        <div class="mt-4 mr-3"> -->
      <img
        style="height: 75px"
        class="sidebar-header"
        src="/images/logo-white.png"
        width="200" />
      <!-- <i  class="bx bx-menu"></i> -->
      <!-- </div>
      </div> -->
      <ul class="metismenu" id="menu">
        <li v-if="details.role == 'ADMIN'">
          <a
            id="admin"
            :class="{ active: pageName.includes('admin') }"
            style="cursor: pointer"
            v-on:click="$router.push('/dashboard')">
            <img src="/images/manage-user.png" />

            <span class="menu-title" style="font-size: 12px">Dashboard</span>
          </a>
        </li>
        <li v-if="details.role == 'ADMIN'">
          <a
            id="admin"
            :class="{ active: pageName.includes('admin') }"
            style="cursor: pointer"
            v-on:click="$router.push('/appointment-offcial')">
            <img src="/images/manage-user.png" />

            <span class="menu-title" style="font-size: 12px"
              >Manage CG Appointment Official</span
            >
          </a>
        </li>
        <li v-if="details.role == 'ADMIN'">
          <a
            id="admin"
            :class="{ active: pageName.includes('admiddddn') }"
            style="cursor: pointer"
            v-on:click="$router.push('/settlement-offcial')">
            <img src="/images/manage-user.png" />

            <span class="menu-title" style="font-size: 12px"
              >Manage Settlement Official</span
            >
          </a>
        </li>
        <li v-if="details.role == 'ADMIN'">
          <a
            id="admin"
            :class="{ active: pageName.includes('admiddddn') }"
            style="cursor: pointer"
            v-on:click="$router.push('/field-inspector-offcial')">
            <img src="/images/manage-user.png" />

            <span class="menu-title" style="font-size: 12px"
              >Manage CG Wellfare Inspector</span
            >
          </a>
        </li>
        <li
          v-if="
            details.role == 'ADMIN' ||
            details.role == 'APPOINTMENT_OFFICIAL' ||
            details.role == 'SETTLEMENT_OFFICIAL'
          ">
          <a
            id="admin"
            :class="{ active: pageName.includes('admiddddn') }"
            style="cursor: pointer"
            v-on:click="$router.push('/manage-grievance')">
            <img src="/images/Success Stories.png" />

            <span class="menu-title" style="font-size: 12px"
              >Profile Submitted</span
            >
          </a>
        </li>
        <li
          v-if="
            details.role == 'ADMIN' || details.role == 'APPOINTMENT_OFFICIAL'
          ">
          <a
            id="admin"
            :class="{ active: pageName.includes('admiddddn') }"
            style="cursor: pointer"
            v-on:click="$router.push('/manage-appointment')">
            <img src="/images/subscription-plan.png" />
            <span class="menu-title" style="font-size: 12px"
              >Manage CG Appointment
            </span>
          </a>
        </li>
        <li
          v-if="
            details.role == 'ADMIN' || details.role == 'SETTLEMENT_OFFICIAL'
          ">
          <a
            id="admin"
            :class="{ active: pageName.includes('admiddddn') }"
            style="cursor: pointer"
            v-on:click="$router.push('/manage-settlement')">
            <img src="/images/reports.png" />

            <span class="menu-title" style="font-size: 12px"
              >Manage Settlement
            </span>
          </a>
        </li>
        <li
          v-if="
            details.role == 'ADMIN' ||
            details.role == 'APPOINTMENT_OFFICIAL' ||
            details.role == 'SETTLEMENT_OFFICIAL'
          ">
          <a
            id="admin"
            :class="{ active: pageName.includes('admiddddn') }"
            style="cursor: pointer"
            v-on:click="$router.push('/manage-feedback')">
            <img src="/images/Success Stories.png" />

            <span class="menu-title" style="font-size: 12px"
              >Manage Feedbacks</span
            >
          </a>
        </li>
        <li
          v-if="
            details.role == 'ADMIN' ||
            details.role == 'APPOINTMENT_OFFICIAL' ||
            details.role == 'SETTLEMENT_OFFICIAL'
          ">
          <a
            id="admin"
            :class="{ active: pageName.includes('admiddddn') }"
            style="cursor: pointer"
            v-on:click="$router.push('/manage-profile-time-log')">
            <img src="/images/Success Stories.png" />

            <span class="menu-title" style="font-size: 12px"
              >Assign Time Log</span
            >
          </a>
        </li>
      </ul>
    </div>
    <header class="top-header">
      <nav class="navbar navbar-expand">
        <div class="left-topbar d-flex align-items-center">
          <a href="javascript:;" class="toggle-btn">
            <i class="bx bx-menu"></i>
          </a>
        </div>
        <div class="right-topbar ml-auto">
          <ul class="navbar-nav" style="max-height: 60px !important">
            <li class="nav-item dropdown dropdown-user-profile">
              <a
                style="max-height: 60px !important"
                class="nav-link dropdown-toggle dropdown-toggle-nocaret p-0"
                href="javascript:;"
                data-toggle="dropdown">
                <div class="media user-box align-items-center">
                  <div class="media-body user-info">
                    <p class="user-name mb-0" id="loginuser-name">
                      {{ details.first_name }} {{ details.last_name }}
                    </p>
                  </div>
                  <img
                    :src="details.image ? details.image : '/img/user-pic.png'"
                    class="user-img"
                    alt="user avatar"
                    id="loginuser-image" />
                </div>
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <router-link to="/my-profile">
                  <a class="dropdown-item">
                    <i class="bx bx-user"></i>
                    <span>Profile</span>
                  </a>
                </router-link>
                <div class="dropdown-divider mb-0"></div>
                <a
                  class="dropdown-item"
                  style="cursor: pointer"
                  v-on:click="logout">
                  <i class="bx bx-power-off"></i>
                  <span>Logout</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  </div>
</template>
<script>
import api from "../services/WebService";
export default {
  name: "Menu",
  data() {
    return {
      pageName: "",
      details: {},
      isShow: false,
    };
  },
  watch: {
    "$route.fullPath"(newValue) {
      var pathname = newValue.replace("/", "");
      this.pageName = pathname;
    },
  },
  mounted() {
    this.pageName = this.$route.fullPath.replace("/", "");
    console.log("object", this.details.first_name);
    this.getLoginUserDetail();
  },
  methods: {
    redirectTo() {
      localStorage.removeItem("tab");
      this.$router.push("/success-stories");
    },
    isShowList() {
      this.isShow = !this.isShow;
    },
    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },
    getLoginUserDetail() {
      api
        .getAPI({
          _action: "/me",
        })
        .then((res) => {
          if (res.result && res.result.role) {
            localStorage.setItem("role", res.result.role);
            this.details = res.result;
          }
        })
        .catch(() => {});
    },
  },
};
const accSingleTriggers = document.querySelectorAll(".js-acc-single-trigger");
accSingleTriggers.forEach((trigger) =>
  trigger.addEventListener("click", toggleAccordion)
);

function toggleAccordion() {
  const items = document.querySelectorAll(".js-acc-item");
  const thisItem = this.parentNode;
  items.forEach((item) => {
    if (thisItem == item) {
      thisItem.classList.toggle("is-open");
      return;
    }
    item.classList.remove("is-open");
  });
}
</script>
